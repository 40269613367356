function detectModules(document) {
    return document.querySelectorAll("[data-module-id]");
}

function loadModule(moduleFileName) {
    return import(/* webpackChunkName: "[request]" */ `./modules/${moduleFileName}`);
}

function initModule(importedModule, domNode) {
    const moduleInstance = new importedModule.default(domNode);

    moduleInstance.init();
}

export function bootModules(document) {
    const moduleDomNodes = detectModules(document);

    for (const moduleNode of moduleDomNodes) {
        const moduleFileName = moduleNode.dataset.moduleId;

        loadModule(moduleFileName).then(importedModule => initModule(importedModule, moduleNode));
    }
}
